@import url(https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&family=Poppins:wght@200;500&family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap);
.header {
  margin-top: 10px;
  margin-left: 10%;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header nav{
 
  
  width: 300%;
  padding: 10px

}

.header img {
  width: 100px;
}
.header button {
  padding: 10px;
  background: #4b4b4a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #363636ad;
  border: 1px solid #000000;
  border-radius: 48px;
  width: 150px;
  height: 50px;
  padding: 5px 15px;
  cursor: pointer;
  color: white
}

@media only screen and (max-width: 768px) {
  .header {
    width: 100%;
    margin: 0;
    padding: 10px 20px;
  }
  .header img {
    width: 60px;
  }
  .header button {
    padding: 10px;
    width: 100px;
    height: 30px;
    padding: 5px 15px;
  }
}
 

.nav-link{
  color: green !important;
}
.main-section {
  text-align: center;
  padding: 20px 0px;
}
.main-section .headings {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #ebeae8;

  font-size: 1.5rem;
  margin-bottom: 0.5em;
  font-family: "Poppins", sans-serif;
}
.main-section .main-section-form {
  width: 50%;
  margin-left: 25%;
  text-align: left;
  background: transparent linear-gradient(180deg, #272627c9 0%, #01271096 100%)
    0% 0% no-repeat padding-box;
  border-radius: 25px;
  padding: 25px;
  color: #ffffff;
}

.main-section .main-section-form .form-group {
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  padding-right: 15px;
  border: 1px solid #707070;
  border-radius: 6px;
}
.main-section .main-section-form .form-group input {
  width: 60%;
  border: none;
  outline: none;
}
.main-section .main-section-form .form-group div {
  display: flex;
  justify-content: end;
  align-items: center;
}
.main-section .main-section-form .form-group div img {
  width: 25px;
  height: 25px;
}
.main-section .main-section-form .form-group div p {
  color: #000000;
  margin-left: 10px;
}
.referal {
  width: 100%;
  outline: none;
  border: 1px solid #707070;
  border-radius: 6px;
  padding: 10px;
}

.mgtp {
  margin: 0.3em 0;
}

.link {
  margin: 1em 0;
  
}
.link a {
  color: #e9e5de;
  text-decoration: none;
  padding: 8px;
  background: #363636ad;
  border-radius: 8px;
}
.buy {
  text-align: center;
  padding: 0.7em 2em;
  background-color: #f3eeec;
  color: #000000;
  border: none;
  cursor: pointer;
  margin: 0.7em 0;
  border-radius: 6px;
}

.smart {
  display: flex;
  justify-content: center;
}
.smart p {
  margin-left: 0.5em;
}
.progres-bar-wrapper {
  border: 5px solid rgb(25, 105, 5);
  width: 100%;
  margin: 5px auto 5px auto;
  border-radius: 35px;
  overflow: hidden;
}

.progress-inner {
  height: 20px;
  border-radius: 35px;
  width: 10%;
  background: rgb(0, 161, 199);
  background: linear-gradient(
    105deg,
    rgb(13, 2, 63) 0%,
    rgb(223, 9, 27) 88%
  );
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.progress-parent {
  /* display: flex; */
  color: white;
  max-width: 780px;
  width: 100%;
  margin: 30px auto;
  padding: 0 20px;
}
.progress-btm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
@media only screen and (max-width: 768px) {
  .main-section .main-section-form {
    width: 90%;
    margin-left: 5%;
    text-align: left;
  }
  .main-section .headings {
    font-size: 1rem;
    margin-bottom: 1em;
  }
}
button:disabled,button[disabled]
{
  cursor:no-drop !important
}
.footer-icon ul li{display: inline-block; padding: 0px 5px;}
.footer-icon ul li:first-child{padding-left: 0;}
.footer-icon ul li a{display: block; width: 40px; height: 40px; line-height: 40px; text-align: center; border-radius: 100%; font-size: 16px;}




.calculator-container{
  font-family: Arial, Helvetica, sans-serif;
  width: 80%;
  margin: 0 auto;
}
table{
  border-collapse: collapse;
  width: 100%;
  padding: 15px;
  font-family: Arial, Helvetica, sans-serif;
 
  border-radius: 5px;
  box-shadow: 0 0 10px #d4cdcd;
}
th,td{
  font-size: 12px;
  text-align: center;
  border: 1px solid #333;
  
  max-width: 70px;
  box-sizing: border-box;
  padding: 5px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}
th{
  background: #191818;
  font-size: 12px;
  color: #fff;
   border-color: #fff;
}
td{
  background-color: #fff;
  width: 100px;
  height: 30px;
  font-size: 16px;
  color: #000;
  
}
td input{
  max-width: 100%;
  background: #eee;
  border: none;
  height: 100%;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 2px;
}
td input:focus{
  outline: 0;
}
tr th:first-child,
tr td:first-child{
  width: 180px;
  text-align: left;
  font-size: 16px;
}
.res-section{
  width: 100%;
  margin: 10px auto;
  background: #191818;
  padding: 10px 30px;
  box-sizing: border-box;
  
}
.res-row{
  width: 100%;
  display: flex;
  /* background: rgb(175, 175, 175); */
  /* box-shadow: 0 0 20px #eee; */
  margin-bottom: 10px;
}
.res-row .res-text,
.res-row .res-value{
  
  height: 30px;
  line-height: 30px;
  
  /* border-bottom: 1px solid #000; */
 
  
}
.res-row .res-text{
  color: #fff;
  width: 70%;
  font-size: 15px;
}
.res-row .res-value{
  color: #FBB32C;
  width: 30%;
  font-weight: bold;
  text-align: right;
  font-size: 20px;
}

/* //// */
/* input append/prepend group styles */
.append-input-group,
.prepend-input-group{
  display: flex;
}
.append-input-group input{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #dae0e8;
  border-right: none;
  padding-right: 0 !important;
  width: 100%;

  
}
.prepend-input-group input{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #dae0e8;
  border-left: none;
  padding-left: 0!important;
  width: 100%;
}
.append-input-group input:focus{
  border-right: none;
}
.prepend-input-group input:focus{
  border-left: none;
}
/* .append-input-group input:focus ~ span,
.prepend-input-group input:focus ~ span{
  border-color: red;
} */

.append-input-group span,
.prepend-input-group span{
  border: 1px solid #dae0e8;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding: 0px 3px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /* min-width: 30px; */
  justify-content: center;
  background: #eee;
  padding-top: 1px;
  color: #000;
  
}
.append-input-group span{
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.prepend-input-group span{
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.sec {
  margin-top: 2em;
position: center;  
  padding: 15px;
  background-color: #000;
  border: 2px solid #4CAF50;
text-align: center;
color: #eb8b2b;
}
.tokenname {
  margin-top: -2px;
  margin-bottom: 1px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-image: url(/static/media/bg-texture-05.ad8243bc.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Poppins", sans-serif;
  line-height: 1;
}
.bold {
  font-weight: bolder;
}
.center-navbar {
  text-align: center;
}

.center-navbar .navbar {
  display: inline-block;
}

.center-navbar .container {
  width: 100%;
}

